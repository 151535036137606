<template>
  <div>
    <!-- v-dialog baslıyor -->
    <v-app>
      <!-- v-dialog bitti -->
      <div class="page-card">
        <v-container>
          <v-row>
            <v-col md="6">
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      required
                      v-model="selected.name"
                      label="Ad"
                      filled
                      :rules="rules.name"
                      disabled
                    ></v-text-field> </v-col
                ></v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selected.surname"
                      label="Soyad"
                      filled
                      :rules="rules.name"
                      disabled
                    ></v-text-field
                  ></v-col>
                </v-row>
              </div>

              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selected.email"
                      label="E-posta"
                      filled
                      :rules="rules.name"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selected.username"
                      label="Kullanıcı Adı"
                      filled
                      :rules="rules.name"
                      disabled
                    ></v-text-field
                  ></v-col>
                </v-row>
              </div>

              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      v-model="selected.adminAuthority"
                      label="Yetki Seçiniz..."
                      filled
                      disabled
                      :items="adminauthorityitems"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      :items="titleitems"
                      label=" Ünvan Seçiniz..."
                      v-model="selected.title"
                      filled
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
              </div>

              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      :items="centeritems"
                      label=" Merkez Seçiniz..."
                      v-model="selected.centerId"
                      filled
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      label="Bölüm Seçiniz..."
                      v-model="selected.specialtyDepartment"
                      filled
                      :rules="rules.name"
                      disabled
                      :items="specialtyDepartmentitems"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      auto-select-first
                      clearable
                      label="Organizasyon Seçiniz.."
                      v-model="selected.organizationId"
                      filled
                      :items="organizationItems"
                      item-text="organizationName"
                      item-value="id"
                      disabled
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col md="6">
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selected.phoneNumber"
                      label="Cep Telefonu"
                      filled
                      :rules="rules.name"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selected.landPhone"
                      label="Sabit Telefon"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      label="Ülke Seçiniz..."
                      v-model="selected.country"
                      filled
                      disabled
                      :items="countryitems"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      label="İl Seçiniz..."
                      v-model="selected.city"
                      filled
                      disabled
                      :items="cityitems"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-select
                      label="İlçe Seçiniz..."
                      v-model="selected.district"
                      filled
                      disabled
                      :items="districtitems"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selected.postCode"
                      label="Posta kodu"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="selected.address"
                      label="Adres"
                      filled
                      disabled
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <div class="form-group">
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Onay Tarihi"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          filled
                          disabled
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
.v-card-title {
  position: absolute;
  right: 0;
  color: rgb(72, 46, 88);
}
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
//import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "usersview",
  data() {
    return {
      objeler: {
        id: "",
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      currentid: null,
      rules: {
        name: [(val) => (val || "").length > 0 || "Bu alan doldurulmalı"],
      },

      selected: {
        id: "",
        name: "",
        surname: "",
        usercode: "",
        username: "",
        adminAuthority: "",
        title: "",
        center: {
          id: "",
        },
        centerId: "",
        organization: "",
        organizationId: "",
        specialtyDepartment: "",
        phoneNumber: "",
        landPhone: "",
        email: "",
        country: "",
        city: "",
        district: "",
        createDate: "",
        deleteDate: "",
        //postCode: "",
        address: "",
      },
      adminauthorityitems: [
        { text: "Normal User", value: 0 },
        { text: "CRO", value: 1 },
        { text: "Global Admin", value: 2 },
      ],
      titleitems: [
        { text: "Prof Dr.", value: "Prof. Dr." },
        { text: "Doç Dr.", value: "Doç Dr." },
        { text: "Uzm. Dr.", value: "Uzm. Dr." },
        { text: "Dr.", value: "Dr." },
        { text: "Yrd. Doç. Dr.", value: "Yrd. Doç. Dr." },
        { text: "Dr. Öğr. Üyesi", value: "Dr. Öğr. Üyesi" },
        { text: "Boş", value: "Boş" },
      ],
      centeritems: [],
      specialtyDepartmentitems: [
        { text: "Kardiyoloji", value: "Kardiyoloji" },
        { text: "Kardiyoloji ABD", value: "Kardiyoloji ABD" },
        { text: "Nefroloji", value: "Nefroloji" },
        { text: "Diğer", value: "Diğer" },
      ],
      countryitems: [],
      cityitems: [],
      districtitems: [],
      temp: "",
      selectedPageItemName: "",
      organizationItems: [],
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
   
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Kullanıcı Görüntüleme" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/userlist" },
        { title: "Kullanıcı Görüntüleme" },
      ]);
    }

    // this.$on('getCurrentid');
    // var temp = localStorage.getItem("itemler");
    // this.objeler = JSON.parse(temp);
    // this.currentid = this.objeler.id;
    // this.getCurrentUserinfo(this.currentid);
    this.temp = this.$route.params.itemid;
  
    this.getCurrentUserinfo(this.temp);
    this.getOrganization();

    this.fillAdminAuthorityItems();
  },
  methods: {
    getOrganization() {
      ApiService.get("organizations")
        .then(({ data }) => {
          this.organizationItems = data.result;
          //console.log("organization itemleri geldi", this.organizationItems);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    fillAdminAuthorityItems() {
      if (this.globaladmin) {
        this.adminauthorityitems = [
          { text: "Normal User", value: 3 },
          { text: "CRO", value: 1 },
          { text: "Global Admin", value: 2 },
        ];
      } else {
        this.adminauthorityitems = [{ text: "Normal User", value: 3 }];
      }
    },
    stepUserEdit() {
      this.$router.push({ name: "usersedit", params: { itemid: this.temp } });
    },
    getCurrentUserinfo(item) {
     
      ApiService.get("/user", item)
        .then(({ data }) => {
          console.log("User bilgileri geldi", data);

          this.selected = data.result;
          // this.selected.centerId!=null ? null:this.selected.center={id:""};
          if (this.selected.centerId != null) {
            this.centeritems.push({
              text: data.result.center.centerName,
              value: data.result.center.id,
            });
          }

         
          this.countryitems.push({
            text: this.selected.country,
            value: this.selected.country,
          });
          this.cityitems.push({
            text: this.selected.city,
            value: this.selected.city,
          });
          this.districtitems.push({
            text: this.selected.district,
            value: this.selected.district,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
